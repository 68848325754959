import React, { useEffect, useState } from "react";
import HeaderHome from "../components/headerHome";
import FooterHome from "../components/footerHome";
import LessonsSidebar from "../components/LessonsSidebar";
import Lesson from "../components/Lesson";
import Loading from "../components/Loading";
import Auth from "../components/Auth";
import { getUserQuiz, setUserAnswers } from "../api/quiz";
import styled from "styled-components";

export const query = graphql`
  query {
    categories: allContentfulActionCategories {
      nodes {
        title
        questionIDs
        order
      }
    }
    lessons: allContentfulLessons {
      nodes {
        lessonId
        lessonName
        lessonDescription {
          lessonDescription
        }
        tip1Title
        tip1Description {
          tip1Description
        }
        tip2Title {
          tip2Title
        }
        tip2Description {
          tip2Description
        }
        tip3Title
        tip3Description {
          tip3Description
        }
        videoName
        videoPlaceholderImage {
          fluid {
            src
          }
        }
        cloudinaryVideo {
          secure_url
          original_url
        }
        practiceChild
        correctPracticeLevels
        failedMessage {
          failedMessage
        }
      }
    }
  }
`;

const QuizLayoutContainer = styled.div`
  width: 100%;
`;

const SideBar = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;
const QuestionArea = styled.div`
  padding: 0 20px;
`;

const QuizLayoutWrapper = styled.div`
  width: 100%;
  display: grid;
  padding-top: 74px;
  grid-template-columns: 350px 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const StyledLoading = styled(Loading)`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Lessons = ({ data }) => {
  const [isActionLoading, setActionLoading] = useState(false);
  const {
    categories: { nodes: categories },
    lessons: { nodes: lessons },
  } = data;

  const [currentLesson, setCurrentLesson] = useState(
    lessons.find(lesson => lesson.lessonId === "F1")
  );

  const [userAnswers, setUserAnswersState] = useState({});
  const [isLoading, setLoading] = useState(true);

  const lessonCategories = categories
    .sort((a, b) => a.order - b.order)
    .map(category => ({
      title: category.title,
      order: category.order,
      lessons: category.questionIDs.map(
        questionId =>
          lessons.find(lesson => lesson.lessonId === questionId) || {}
      ),
    }));

  const sortedLessons = lessonCategories
    .map(item => item.lessons)
    .reduce((prev, next) => prev.concat(next));

  useEffect(async () => {
    try {
      setLoading(true);
      const { userAnswers, hasVisitedProgram } = await getUserQuiz();
      if (!hasVisitedProgram) {
        // window.location.href = '/child-program'
        // replace /child-program with /toddler-talk-plan-tutorial url
        window.location.href = '/toddler-talk-plan-tutorial'
      } else {
        let newUserAnswers = {};
        userAnswers.forEach(answer => {
          newUserAnswers[answer.questionId] = {
            answers: answer.answers,
            result: answer.result,
          };
        });

        setUserAnswersState(newUserAnswers);
        setCurrentLesson(
          sortedLessons.find(
            lesson =>
              !newUserAnswers[lesson.lessonId] ||
              (newUserAnswers[lesson.lessonId] &&
                !newUserAnswers[lesson.lessonId].result)
          ) || sortedLessons[0]
        );
      }
      setLoading(false);
    } catch (err) {
      window.location.href = "/not-found";
      setLoading(false);
    }
  }, []);

  const handleClickLesson = lesson => {
    setActionLoading(true);
    setTimeout(() => {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      setCurrentLesson(lesson);
      setActionLoading(false);
    }, 2000);
  };

  const handleWatchVideo = async (enableRedirection) => {
    setActionLoading(true);
    let currentLessonId = currentLesson.lessonId;
    const newUserAnswers = {
      ...userAnswers,
      [currentLesson.lessonId]: {
        answers: currentLesson.correctPracticeLevels,
        result: true,
      },
    };
    setUserAnswersState(newUserAnswers);
    await setUserAnswers({
      userAnswers: Object.keys(newUserAnswers).map(key => ({
        questionId: key,
        ...newUserAnswers[key],
      })),
    });

    setActionLoading(false);

    setCurrentLesson(
      sortedLessons.find(
        lesson =>
          !newUserAnswers[lesson.lessonId] ||
          (newUserAnswers[lesson.lessonId] &&
            !newUserAnswers[lesson.lessonId].result)
      ) || sortedLessons[0]
    );

    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    let lastLessonId = sortedLessons[sortedLessons.length - 1]['lessonId'];
    if ((currentLessonId === lastLessonId) || enableRedirection) {
      window.location.href = '/program-finished'
    }

  };

  const renderActions = isLoading ? (
    <Loading />
  ) : (
    <QuizLayoutContainer>
      {isActionLoading && <StyledLoading />}
      <HeaderHome />
      <QuizLayoutWrapper>
        <SideBar>
          <LessonsSidebar
            lessonCategories={lessonCategories}
            userAnswers={userAnswers}
            onClickLesson={handleClickLesson}
          />
        </SideBar>
        <QuestionArea>
          <Lesson
            data={currentLesson}
            userAnswers={userAnswers}
            onClickNext={(enableRedirection) => { handleWatchVideo(enableRedirection) }}
          />
        </QuestionArea>
      </QuizLayoutWrapper>
      <FooterHome />
    </QuizLayoutContainer>
  );

  return <Auth>{renderActions}</Auth>;
};

export default Lessons;
